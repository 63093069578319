import MSP from "../../../mobile/src/mixins/msp.js"
import _calendario from './calendario.js';
import moment from "moment";
moment.locale('it');
export default {
    data: function () {
        return {
            today: _calendario.date2ymd(),
        }
    },
    created: function() {
    },
    watch: {
    },
    computed:
    {
    },
    mounted: function()
    {


    },
    methods: {

        MSP: function () {
            return MSP;
        },

        getMonday: MSP.getMonday,

        colorGray: function (index,min_in,max_in,min_out,max_out) {
            let i = MSP.normalized(max_in-index, min_in, max_in, min_out,max_out);

            return "rgb("+i+","+i+","+i+")"
        },

        formatCalendarDay: function(day) {
            if (!day) {
                return "";
            }
            let formattedDate =  moment(day).format('ddd DD MMM YY');
            return formattedDate;
        },
        formatShortDay: function(day) {
            if (!day) {
                return "";
            }
            let formattedDate =  moment(day).format('DD MMM Y');
            return formattedDate;
        },


        round: function (value) {
            return MSP.int(value);
        },

        hasHistory: function () {
            return MSP.history.count() > 1;
        },

        isValidDate: function (d) {
            return d instanceof Date && !isNaN(d);
        },

        date2string: function (date) {
            return MSP.formatDateYmd(date);
        },

        getPercentuale: function (a, b, suffix = false) {
            if (!b) {
                return "";
            }
            const result = Math.round((a/b*100)*10)/10;
            return (suffix) ? result + suffix : result;
        },

        getCommentoPercentuali: function () {
            return MSP.PERCENTUALI || [];
        },
        des_um: function (id) {
            var um = this.ums.filter(function(el){return el.um_id===id});
            if (um.length === 1)
            {
                return um[0].des_um;
            }
        },

        isDistance: function (um_id) {
            return MSP.isDistance(um_id);
        },
        hasRating: function (sport_id) {
            let no_rating = [
                MSP.ID_CUSTOM,
                MSP.ID_ALIMENTAZIONE,
                MSP.ID_RIPOSO
            ];
            return no_rating.indexOf(sport_id) === -1;
        },

        hasFeedback: function (sport_id) {
            let no_feedback = [
                MSP.ID_RIPOSO
            ];
            return no_feedback.indexOf(sport_id) === -1;
        },

    canExport: function (sport_id) {
      return MSP.SPORT.find(el=>el.id==sport_id && +el.garmin);
    },

    hasZone: function (sport_id) {
      return MSP.hasZone(sport_id);
    },

        hasDistance: function (sport_id) {
            return MSP.hasDistance(sport_id);
        },

        hasGraficoAndature: function (params) {
            return MSP.hasGraficoAndature(params);
        },

        isPodismo: function (sport_id) {
            return MSP.isPodismo(sport_id);
        },

        isCiclismo: function (sport_id) {
            return MSP.isCiclismo(sport_id);
        },

        isNuoto: function (sport_id) {
            return MSP.isNuoto(sport_id);
        },

        isPalestra: function (sport_id) {
            return MSP.isPalestra(sport_id);
        },
        isSci: function (sport_id) {
            return MSP.isSci(sport_id);
        },

        hasComputing: function (sport_id) {
            return MSP.hasCalcoli(sport_id);
        },

        secondsToHms: function (d) {
            return MSP.sec2hms(d);
        },

        mt2km: function (val) {
            return Math.round(MSP.mt2km(val)* 100) / 100;
        },

        sec2hms: function (val) {
            return MSP.sec2hms(val);
        },

        hms2sec: function (hms) {
            return MSP.hms2sec(hms);
        },

        formatoRisultato: function(val, um, sport_id, hasIcons = false) {
            const icons = {
                time: "<i class='msp-icon-time'></i>",
                distance: "<i class='msp-icon-distance'></i>",
            };
            let value;
            let icon;
            if (um === 'sec') {
                value = MSP.sec2hms(val);
                icon = icons.time;
            } else {
                icon = icons.distance;
                if (this.isNuoto(sport_id)) {
                    value = Math.round(val) + " mt";
                } else {
                    value = (val/1000).toFixed(1) + " km";
                }
            }

            if (hasIcons) {
                value = icon+value;
            }
            return value;
        },
        formatoRisultatoTempoDistanza: function(totale, sport_id, sep=" / ", hasIcons = false) {
            let out = [];
            out.push(this.formatoRisultato(totale.sec, 'sec', sport_id, hasIcons));
            if (this.hasDistance(sport_id)) {
                out.push(this.formatoRisultato(totale.mt, 'mt', sport_id, hasIcons));
            }
            return out.join(sep);
        },
        formatDensita: function (densita) {
            return MSP.formatDensita(densita);
        },

        formatCalorie: function (cal) {
            return MSP.formatCalorie(cal);
        },
        formatBpm: function (bpm) {
            return MSP.formatBpm(bpm);
        },
        formatPotenza: function (watt) {
            return MSP.formatPotenza(watt);
        },
        formatCadenza: function (cadenza) {
            return MSP.formatCadenza(cadenza);
        },
        formatElevazione: function (elev) {
            return MSP.formatElevazione(elev);
        },
        formatKm: function (km) {
            return Math.round(km * 100) / 100 + " km";
        },
        formatData: function (data) {
            return MSP.convertDate(data,"/");
        },
        formatDataOra: function (dataOra) {
            let a_dataOra = dataOra.split(" ");
            let data = MSP.convertDate(a_dataOra[0],"/");
            let ora = a_dataOra[1];
            return data+" "+ora;
        },

        formatDate: function (date) {
            return date && MSP.formatDate(date) || "";
        },

        calcoloPassoNuoto: function (sec) {
            return MSP.calcoloPassoNuotoSec(sec);
        },
        calcoloPassoRunning: function (sec) {
            return MSP.calcoloPassoRunningSec(sec);
        },
        calcoloVelocita: function (vel) {
            return MSP.calcoloVelocita(vel);
        },

        convertTimeUm: function (seconds) {
            return MSP.sec2hms(seconds);
        },

        convertDistanceUm: function (meters) {
            return MSP.formatMtBySport(meters, this.sport_id);
        },

        getDefaultUmBySport: function () {
            if (!(this.ums && this.ums.length)) {
                return undefined;
            }
            return this.ums[0].um_id;
        },
        renderDistanza: function (allenamento) {
            if (!allenamento && allenamento.totalone) return "";
            let dislivello;
            let mt = allenamento.totalone.mt;
            if (allenamento.dislivello) {
                dislivello = " - " + allenamento.dislivello + " (D+)";
            } else {
                dislivello = "";
            }

            let distanza =  this.convertDistanceUmFromIdSport(mt,allenamento.sport_id);
            let render = distanza + dislivello;

            return render;
        },

        kmh2passoKm: function (seconds) {
            return MSP.kmh2passoKm(seconds);
        },

        kmh2passo100mt: function (seconds) {
            return MSP.kmh2passo100mt(seconds);
        },

        formatDettaglioNote: function (allenamento) {
            let desc = "";
            allenamento.dettagli && allenamento.dettagli.length && allenamento.dettagli.forEach(
                (d)=>{
                    desc += "<strong>"+d.des_attivita+"</strong><br>";
                    desc += "<div class='descrizione'>"+d.note_attivita+"</div>";
                }
            );
            return desc;
        },

        formatDettaglioAndature: function (allenamento) {
            if (!allenamento) { return ""; }

            let desc = "";
            allenamento.dettagli && allenamento.dettagli.length && allenamento.dettagli.forEach(
                (d)=>{
                    desc += "<strong>"+d.des_attivita+"</strong><br>";
                    if (d.note_attivita) {
                        desc += "<div class='tooltip--descrizione'>"+d.note_attivita+"</div>";
                    }
                    if (d.serie) {
                        let descrizione = "";
                        d.serie.forEach(
                            s=>{
                                s.andature && s.andature.forEach(
                                    a=>{
                                        descrizione = a.descrizione?descrizione+"\n"+a.descrizione:descrizione;
                                    }
                                )
                            }
                        )
                        desc += "<div class='tooltip--descrizione'>"+descrizione+"</div>";
                    }
                }
            );
            return desc;


        },


        formatDettaglio: function (allenamento) {
            let desc = "";
            allenamento.dettagli && allenamento.dettagli.length && allenamento.dettagli.forEach(
                (d)=>{
                    desc += "<strong>"+d.des_attivita+"</strong><br>";
                    if (d.note_attivita) {
                        desc += "<div class='tooltip--descrizione'>"+d.note_attivita+"</div>";
                    }
                    if (d.serie) {
                        let descrizione = "";
                        d.serie.forEach(
                            s=>{
                                s.andature && s.andature.forEach(
                                    a=>{
                                        descrizione = a.descrizione?descrizione+"\n"+a.descrizione:descrizione;
                                    }
                                )
                            }
                        )
                        desc += "<div class='tooltip--descrizione'>"+descrizione+"</div>";
                    }
                }
            );
            return desc;
        },

        getUnitFromSport: function (sport_id) {
            if (!sport_id) { return ""; }
            let unit = "";
            switch (MSP.int(sport_id)) {
                case MSP.sport_id.podismo:
                    unit ="km" ;
                    break;
                case MSP.sport_id.ciclismo:
                    unit = "km" ;
                    break;
                case MSP.sport_id.sci:
                    unit = "km" ;
                    break;
                default:
                    unit =  "mt" ;

            }
            return unit;
        },


        convertDistanceFromIdSport: function (meters, sport_id) {
            if (!sport_id) {
                return "";
            }
            let distance = "";
            switch (MSP.int(sport_id)) {
                case MSP.sport_id.podismo:
                    distance = Math.round(MSP.um2um(meters, MSP.UMS.mt, MSP.UMS.km ) * 10) / 10;
                    break;
                case MSP.sport_id.ciclismo:
                    distance = Math.round(MSP.um2um(meters, MSP.UMS.mt, MSP.UMS.km ) * 10) / 10;
                    break;
                case MSP.sport_id.sci:
                    distance = Math.round(MSP.um2um(meters, MSP.UMS.mt, MSP.UMS.km ) * 10) / 10;
                    break;
                default:
                    distance =  Math.round(meters);
            }
            return distance;
        },

        convertDistanceReverseFromIdSport: function (distance, sport_id) {
            if (!sport_id) {
                return "";
            }
            let meters = "";
            switch (MSP.int(sport_id)) {
                case MSP.sport_id.podismo:
                    meters = Math.round(MSP.um2um(distance, MSP.UMS.km, MSP.UMS.mt ));
                    break;
                case MSP.sport_id.ciclismo:
                    meters = Math.round(MSP.um2um(distance, MSP.UMS.km, MSP.UMS.mt ));
                    break;
                case MSP.sport_id.sci:
                    meters = Math.round(MSP.um2um(distance, MSP.UMS.km, MSP.UMS.mt ));
                    break;
                default:
                    meters =  Math.round(distance);

            }
            return meters;
        },


        convertDistanceUmFromIdSport: function (meters, sport_id) {
            return this.convertDistanceFromIdSport(meters, sport_id) + " " + this.getUnitFromSport(sport_id);
        },


        /** aggiorna il grafico se esiste
         * @param {Object} params {
         *  'dettagli': [],
         *  'zone': [],
         *  'zone_attive': [],
         * }
         * @return {Object} gzone
         */
        getZoneGrafico: MSP.getZoneGrafico,


        formatTrimp: function (trimp) {
            let formatted = Math.round(trimp);
            return (!isNaN(formatted)) ? formatted : "";
        },


        setTrimps: function (trimps)
        {
            this.trimps = trimps;
        },

        setMets: function (mets)
        {
            this.mets = mets;
        },

        updateMets: function ()
        {
            // TODO
            // 10 minuti in z2 * coeff. METS z2
            // METS * coeff per avere kcal
            // vedi mets.xlsx da dropbox

            if(!(this.mets && this.mets.length))
            {
                return;
            }
            //TODO-TOTALI: spostare i totali FUORI dai dettagli -->
            let mets = this.allenamento.dettagli.reduce((t, dettaglio)=>{
                for(let zona in dettaglio.totale.zone)
                {
                    let z = dettaglio.totale.zone[zona];
                    let mets = this.mets.find((el)=>(el.zona_id===zona));
                    if (mets)
                    {
                        t += MSP.metsSec(mets.mets, z);
                    }
                }

                return t;
            }, 0);
            this.allenamento = MSP.setProp(this.allenamento, 'mets', mets.toFixed(2));
        },
        /* END: TRIMP, METS */



    }
}
