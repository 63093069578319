/** CRUD con request methods standard per API
*/
import CONFIG from './config.js';

import axios from "axios"
const _api = function (url, method, data = null, auth_token = null) {
    return new Promise((resolve)=>{
        let config = {
            url: url,
            method: method,
        }
        if (auth_token) {
            config.headers = {
                'Authorization': 'Bearer ' + auth_token
            }
        }
        if (data) {
            config.data = data;
        }
        axios(config).then(
            (response)=>{
                let result = response.data;
                resolve(result);
            })
            .catch(()=>{
                resolve({
                    success: false,
                    data: null,
                });
            })
    });
};

const api = {};

/** Converte un object in parametri URI
 * @param {Object} data parametri query get
 * @return {String}
 */
api.URLparams = function (data)
{
    // let params = Object.keys(data).map(function(k) {
    //   return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    // }).join('&');
    const URLparams = new URLSearchParams(Object.entries(data));

    return URLparams.toString();
}


/** Method GET
 * @param {String} endpoint parametri query get
 * @param {String} apiUrl optional percorso per endpoint. Default da CONFIG.apiUrl.
 * apiUrl = apiUrl || CONFIG.apiUrl
 * @return {Promise} status=200: resolve
 */
api.get = function (endpoint, apiUrl, auth_token = null) {
    apiUrl = apiUrl || CONFIG.apiUrl
    let url = apiUrl + "/" + endpoint;
    return _api(url,'get',null,auth_token);
};


/** Method PUT
 * @param {String} endpoint parametri query get
 * @param {Object} data body part della query
 * @param {String} apiUrl optional percorso per endpoint. Default da CONFIG.apiUrl.
 * apiUrl = apiUrl || CONFIG.apiUrl
 * @return {Promise} status=200: resolve
 */
api.put = function (endpoint, data, apiUrl, auth_token = null) {
    apiUrl = apiUrl || CONFIG.apiUrl
    let url = apiUrl + "/" + endpoint;
    return _api(url,'put',data,auth_token);
};

/** Method POST
 * @param {String} endpoint parametri query get
 * @param {Object} data body part della query
 * @param {String} apiUrl optional percorso per endpoint. Default da CONFIG.apiUrl.
 * apiUrl = apiUrl || CONFIG.apiUrl
 * @return {Promise} status=201: resolve (aggiunto record)
 */
api.post = function (endpoint, data, apiUrl, auth_token = null) {
    apiUrl = apiUrl || CONFIG.apiUrl
    let url = apiUrl + "/" + endpoint;
    return _api(url,'post',data,auth_token);
};

/** Method PUT
 * @param {String} endpoint parametri query get
 * @param {String} apiUrl optional percorso per endpoint. Default da CONFIG.apiUrl.
 * apiUrl = apiUrl || CONFIG.apiUrl
 * @return {Promise} status=200: resolve (eliminato)
 */
api.delete = function (endpoint, apiUrl, auth_token = null) {
    apiUrl = apiUrl || CONFIG.apiUrl
    let url = apiUrl + "/" + endpoint;
    return _api(url,'delete',null,auth_token);
};


export { api as default };
